import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import config from './config.json';

const Picture = () =>
{
    const { pictureId } = useParams();
    const [pictureData, setPictureData] = useState()

    useEffect(() =>
    {
        fetch(config.pictureServerUrl + '/' + pictureId, {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + config.token,
            },
        })
            .then((response) =>
            {
                if (response.status === 200)
                {
                    return response.blob()
                }
                /** Simply ignore any errors */
                return undefined
            })
            .then((data) =>
            {
                if (data)
                {
                    setPictureData(URL.createObjectURL(data))
                }
                else
                {
                    setPictureData(undefined)
                }
            })
            .catch((error) =>
            {
                console.log(error);
            })

    }, [pictureId])


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "100vh",
        }}>
            {!pictureData && <div>No image exists with ID: {pictureId}</div>}
            {pictureData &&
                <>
                    <div style={{
                        flex: "1",
                        maxHeight: "90vh",
                    }}>
                        <img
                            src={pictureData}
                            alt='none'
                            style={{
                                maxHeight: "90vh",
                                maxWidth: "100%",
                            }} />
                    </div>
                    <div style={{
                        flex: "1",
                    }}>
                        <a
                            href={pictureData}
                            rel="noopener noreferrer"
                            download={pictureId}>
                            <Button style={{height:"10vh"}}>
                                Save Image
                            </Button>
                        </a>
                    </div>
                </>
            }
        </div>
    );
};
export { Picture };