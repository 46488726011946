import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Picture } from './Picture'
import { Home } from './Home'

function App()
{
  return (
    <Router>
      <Switch>
        <Route exact path='/picture/:pictureId' component={Picture} />
        <Route component={Home} />
      </Switch>
    </Router>
  )
};

export default App;
